export default [
  {
    title: "序号",
    dataIndex: "index",
    customRender: (value, item, index) => index + 1,
  },
  {
    title: "批次编号",
    dataIndex: "number",
  },
  {
    title: "仓库",
    dataIndex: "warehouse_name",
  },
  {
    title: "产品编号",
    dataIndex: "material_number",
  },
  {
    title: "产品名称",
    dataIndex: "material_name",
  },
  {
    title: "客户",
    dataIndex: "client_name",
  },
  {
    title: "库存数量",
    dataIndex: "remain_quantity",
  },
  {
    title: "库存单位",
    dataIndex: "material_name",
  },
  {
    title: "生产日期",
    dataIndex: "production_date",
  },
  {
    title: "到期日期",
    dataIndex: "expiration_date",
  },
  {
    title: '操作',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' },
    width: 100,
  },
];
